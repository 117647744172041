<!--
 * @Description: 
 * @Author: ChenXueLin
 * @Date: 2021-08-16 16:35:08
 * @LastEditTime: 2022-01-05 15:16:45
 * @LastEditors: ChenXueLin
-->
<template>
  <el-breadcrumb separator="/" class="breadcrumb">
    <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
    <!--第二级菜单名称 就要看左侧组件有没有点击指定菜单，没有那就只显示首页 点击就显示当前菜单名称-->
    <transition-group>
      <el-breadcrumb-item
        v-for="item in breadList"
        :key="item.path ? item.path : item.moduleId"
        :to="item.path == currentPath || !item.path ? '' : item"
      >
        <a>{{ item.title ? item.title : item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      breadList: [],
      filterArr: [],
      currentPath: ""
    };
  },
  created() {
    this.currentPath = this.$route.path;
    this.getBreadcrumb();
  },
  watch: {
    $route(newVal) {
      // this.filterArr = [];
      this.currentPath = newVal.path;
      this.getBreadcrumb();
    }
  },
  computed: {
    ...mapGetters(["allMenu"])
  },
  methods: {
    getMatch(modulePid) {
      if (this.filterArr[0] && this.filterArr[0].moduleId === modulePid) {
        return;
      }
      this.filterArr = [];
      let allmenu = this.allMenu;
      let allMenuRoute = [];
      for (var key in allmenu) {
        allMenuRoute.push(allmenu[key]);
      }
      let pTarget = allMenuRoute.find(item => item.moduleId == modulePid);
      if (pTarget) {
        this.filterArr.unshift(pTarget);
      }
      // console.log(pTarget, "pTarget===66");
      return this.filterArr;
    },
    getBreadcrumb() {
      let modulePid = this.$route.meta.modulePid;
      this.getMatch(modulePid);
      let matched = this.filterArr;
      let routeIndex = matched.findIndex(item => {
        if (item.meta) {
          return item.meta.moduleId === this.$route.meta.moduleId;
        } else {
          return false;
        }
      });
      if (routeIndex !== -1) {
        matched = matched.slice(0, routeIndex + 1);
      } else {
        matched.push(this.$route);
      }
      this.filterArr = matched;
      this.breadList = matched;
    }
  }
};
</script>

<style lang="scss">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}</style
>>
